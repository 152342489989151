@use "../../../assets/scss/variables" as v;

.loading{
    width:100%;
    height: 100%;
    text-align: center;

    .spinner-border{
        color:v.$color1;
    }
}