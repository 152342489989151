@use "../../../assets/scss/variables" as v;

.image-area{
    background-color: v.$color4;
    border-radius: 2rem;
    position: relative;

    button{
        position: absolute;
        bottom: 0; left:0; right:0;
    }

    .image-area-error{
        position: absolute;
        left:50%;
        top:50%;
        transform: translate(-50%, -50%);
    }

    img{
        border-radius: 2rem;
        width:100%;
        height:auto;
    }
}