$color1: #13826e;
$color2: #FC5A29;
$color3: rgb(237, 237, 237);
$color4: rgba(19, 130, 110, 0.2);
$color5: rgba(255, 96, 48, 0.5);


/* BOOTSTRAP */
$primary: $color1;
$font-family-sans-serif: "Poppins";
$navbar-light-color: $color2;
$navbar-light-hover-color: $color1;
$nav-link-font-weight:500;
$navbar-nav-link-padding-x: 1rem;
$border-radius: 2rem;
$input-btn-padding-y: 1rem;
$input-btn-padding-x: 2rem; 
$dropdown-padding-y: 2rem;


/* SWAL */
$swal2-confirm-button-background-color: $color1;
$swal2-deny-button-background-color: $color2;